import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const Post = ({pageContext}) => {
    const content = pageContext.content;

    return (
        <Layout>
            <Seo seo={pageContext.seo} />
            <div className="container w-5/6 lg:w-4/6">
                <div className="text-center my-12">
                    <h1 className="inline-block font-bold text-xl mt-10 uppercase w-full text-left"> {pageContext.title} </h1>
                </div>
                <div className="prose ml-auto mr-auto">
                    <div dangerouslySetInnerHTML={{__html: content}} />
                </div>
            </div>

        </Layout>
    )
}

export default Post
